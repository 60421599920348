<template>
    <div>
        <v-card>
            <v-card-actions class="mx-3">
                <v-spacer></v-spacer>
                <v-btn class="mr-3 mb-3 white--text" small color="green" depressed @click="export2xls">Export to XLS</v-btn>
            </v-card-actions>
            <v-card-subtitle>
                <div style="text-align:center; font-weight:bold; font-size:16px; padding:10px">Form Logistik</div>

                <v-row class="compact-form my-2">
                    <v-col cols="1">
                        Tanggal
                    </v-col>
                    <v-col cols="3" class="">
                        <v-menu v-model="dateMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="reportDate" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="reportDate" :allowed-dates="allowedDates"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="1">
                        Volume
                    </v-col>
                    <v-col cols="3" class="">
                        <v-select outlined dense :items="volumes" item-text="description" item-value="code" v-model="volume" hide-details="auto"></v-select>                        
                    </v-col>
                    <v-col cols="2">
                        <v-btn depressed color="primary" @click="runReport">Run</v-btn>
                    </v-col>
                </v-row>                

            </v-card-subtitle>

            <v-card-text class="ml-3 mr-3">

                <div id="printresep" style="overflow:auto">
                    <!-- <table style="width:98%; margin: 0px;" class="pdf-item" ref="tableBOM"> -->
                    <v-simple-table fixed-header height="400px" id="variantTbl" ref="tableBOM">
                        <template v-slot:default>
                            <thead>
                                <!-- <tr>
                                    <th colspan="20">&nbsp;</th>
                                    <th><b>Exp Date</b></th>
                                    <th v-for="header in custHeader" :key="header.do_no"><b>{{header.expired_date | moment}}</b></th>
                                </tr> -->
                                <tr>
                                    <th rowspan="2"><b>Kode</b></th>
                                    <th rowspan="2"><b>Volume</b></th>
                                    <th rowspan="2"><b>Produk</b></th>
                                    <th rowspan="2"><b>Stok Awal</b></th>
                                    <th rowspan="2"><b>Stok Out</b></th>
                                    <th rowspan="2"><b>Sisa Stok</b></th>
                                    <th colspan="7" style="text-align: center"><b>Stock In</b></th>
                                    <th colspan="7" style="text-align: center"><b>Sisa Produksi / Extra</b></th>
                                    <th><b>Exp Date<br>Customer</b></th>
                                    <th class="no-wrap" v-for="header in custHeader" :key="header.do_no">
                                        {{header.expired_date | moment}}<br><b>{{header.customer_name}}</b>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="no-wrap"><b>Sun,&nbsp;<br> {{addDate(0) | moment}}</b></th>
                                    <th class="no-wrap"><b>Mon,&nbsp;<br> {{addDate(1) | moment}}</b></th>
                                    <th class="no-wrap"><b>Tue,&nbsp;<br> {{addDate(2) | moment}}</b></th>
                                    <th class="no-wrap"><b>Wed,&nbsp;<br> {{addDate(3) | moment}}</b></th>
                                    <th class="no-wrap"><b>Thu,&nbsp;<br> {{addDate(4) | moment}}</b></th>
                                    <th class="no-wrap"><b>Fri,&nbsp;<br> {{addDate(5) | moment}}</b></th>
                                    <th class="no-wrap"><b>Sat,&nbsp;<br> {{addDate(6) | moment}}</b></th>
                                    <th class="no-wrap"><b>Sun,&nbsp;<br> {{addDate(0) | moment}}</b></th>
                                    <th class="no-wrap"><b>Mon,&nbsp;<br> {{addDate(1) | moment}}</b></th>
                                    <th class="no-wrap"><b>Tue,&nbsp;<br> {{addDate(2) | moment}}</b></th>
                                    <th class="no-wrap"><b>Wed,&nbsp;<br> {{addDate(3) | moment}}</b></th>
                                    <th class="no-wrap"><b>Thu,&nbsp;<br> {{addDate(4) | moment}}</b></th>
                                    <th class="no-wrap"><b>Fri,&nbsp;<br> {{addDate(5) | moment}}</b></th>
                                    <th class="no-wrap"><b>Sat,&nbsp;<br> {{addDate(6) | moment}}</b></th>
                                    <th class="no-wrap"><b>Produk</b></th>
                                    <th v-for="header in custHeader" :key="header.do_no"><b>{{header.delivery_date | moment2}}</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(obj, idx) in printObj" :key="idx">
                                    <td>{{obj.code}}</td>
                                    <td>{{obj.size}}</td>
                                    <td>{{obj.name}}</td>
                                    <!-- Stok Aktual Awal -->
                                    <td>{{obj.stock_awal | rounded20}}</td> 
                                    <!-- Stok Out -->
                                    <td>{{obj.stock_out | rounded20}}</td>
                                    <!-- Sisa Stok -->
                                    <td>{{Number(obj.stock_awal) + Number(obj.in_total) + Number(obj.ex_total) - Number(obj.stock_out) | rounded20}}</td>
                                    <!-- Stock In -->
                                    <td>{{obj.in_sun | rounded20}}</td>
                                    <td>{{obj.in_mon | rounded20}}</td>
                                    <td>{{obj.in_tue | rounded20}}</td>
                                    <td>{{obj.in_wed | rounded20}}</td>
                                    <td>{{obj.in_thu | rounded20}}</td>
                                    <td>{{obj.in_fri | rounded20}}</td>
                                    <td>{{obj.in_sat | rounded20}}</td>
                                    <!-- Sisa Produksi / Extra -->
                                    <td>{{obj.ex_sun | rounded20}}</td>
                                    <td>{{obj.ex_mon | rounded20}}</td>
                                    <td>{{obj.ex_tue | rounded20}}</td>
                                    <td>{{obj.ex_wed | rounded20}}</td>
                                    <td>{{obj.ex_thu | rounded20}}</td>
                                    <td>{{obj.ex_fri | rounded20}}</td>
                                    <td>{{obj.ex_sat | rounded20}}</td>
                                    <td>{{obj.name}}</td>
                                    <td v-for="header in custHeader" :key="header.do_no">
                                        {{getValueCustomerDetails(header.do_no, obj.code) | rounded20}}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="font-weight-bold">Total</td>
                                    <td class="font-weight-bold" v-for="(total, id2) in totals" :key="id2">{{total | rounded20}}</td> 
                                    <td class="font-weight-bold">Total</td>
                                    <td class="font-weight-bold" v-for="header in custHeader" :key="header.do_no">
                                        {{sumCustomer(header.do_no) | rounded20}}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <!-- </table> -->
                </div>
            </v-card-text>
        </v-card>
        <v-overlay :value="onLoad" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        

    </div>
</template>

<script>
import moment from 'moment'
import { utils, writeFileXLSX } from 'xlsx';

export default {

    data: () => ({
      dialog: false,
      onLoad: false,
      printObj: [],
      dateMenu: false,
      reportDate: null,
      volumes: [],
      volume: null,
      totals: [],
      custHeader: null,
      custDetails: null,
      allowedDates: val => new Date(val+' 00:00:00').getDay() === 0
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('D MMM');
        },
        moment2: function (date) {
            return moment(date).format('ddd, D MMM');
        },
        numbers: function (number) {
            return Number(number).toLocaleString()
        },
    },

    async created() {
        this.volumes = this.miscCodes.filter(element => {
          return element.type=='Botol'
        })
        this.volumes.sort((a, b) => parseFloat(a.code) - parseFloat(b.code));
    },

    methods: {
        addDate(nday){
            const dt = new Date(this.reportDate+ ' 00:00:00')
            dt.setDate(dt.getDate()+ nday)
            return dt
        },
        sumCustomer(do_no) {
            let sum = 0
            this.custDetails.forEach(obj => {
                if (obj.do_no===do_no && obj.size==this.volume)
                    sum += Number(obj.qty_delivered);
            });
            return sum
        },
        getValueCustomerDetails(do_no, variant_code) {
            const data = this.custDetails.find(obj => {
                return (obj.do_no==do_no && obj.variant_code==variant_code)
            })
            if (data) return Number(data.qty_delivered)
            else return 0
        },

        async runReport() {
            this.onLoad = true
            const forms = (await this.$store.dispatch('getObjs',{apidomain:'others/frmlogistik/', parameter:this.reportDate})).data
            this.custHeader = (await this.$store.dispatch('getObjs',{apidomain:'others/frmlogistikcustomerheader/', parameter:this.reportDate})).data
            this.custDetails = (await this.$store.dispatch('getObjs',{apidomain:'others/frmlogistikcustomerdetails/', parameter:this.reportDate})).data
            this.printObj = forms.filter(el => {
                return el.size == this.volume
            })

            this.totals = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
            this.printObj.forEach(obj => {
                this.totals[0] += Number(obj.stock_awal)
                this.totals[1] += Number(obj.stock_out)
                this.totals[2] += (Number(obj.stock_awal) + Number(obj.in_total) + Number(obj.ex_total) - Number(obj.stock_out))
                this.totals[3] += Number(obj.in_sun)
                this.totals[4] += Number(obj.in_mon)
                this.totals[5] += Number(obj.in_tue)
                this.totals[6] += Number(obj.in_wed)
                this.totals[7] += Number(obj.in_thu)
                this.totals[8] += Number(obj.in_fri)
                this.totals[9] += Number(obj.in_sat)
                this.totals[10] += Number(obj.ex_sun)
                this.totals[11] += Number(obj.ex_mon)
                this.totals[12] += Number(obj.ex_tue)
                this.totals[13] += Number(obj.ex_wed)
                this.totals[14] += Number(obj.ex_thu)
                this.totals[15] += Number(obj.ex_fri)
                this.totals[16] += Number(obj.ex_sat)
            });
            console.log(this.printObj)
            this.onLoad = false
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        export2xls() {
            console.log(this.$refs.tableBOM.$el.firstChild.firstChild)
            const wb = utils.table_to_book(this.$refs.tableBOM.$el.firstChild.firstChild)
            writeFileXLSX(wb, "Form-Logistik.xlsx")

        },
    }    
}
</script>

<style scoped>
table, th, td {
  border: 1px solid grey;
  border-collapse: collapse;
}
#printresep th, td {
  padding: 5px;
  font-size: 12px;
}
.box {
    border:1px solid grey;
    height:30px;
    width:100px;    
}
.no-wrap {
    white-space: nowrap;
}
</style>